import React from 'react';
import {
  Container, Row, Col, Tabs, Tab, Card,
} from 'react-bootstrap';
import { OurServicesData, DigitalServiceData } from '../../../data/solutions-db';

export default function ServicesTab() {
  return (
    <section className="pt-5" id="services">
      <Container>
        <Row>
          <h2 className="text-center pb-4">
            {OurServicesData[0].heading}
            {' '}
            <span className="infenox-text-highlight size-line" />
          </h2>
          <Tabs defaultActiveKey="digital" className="services-tab-bg">
            <Tab
              id="digital"
              eventKey={DigitalServiceData[0].key}
              title={DigitalServiceData[0].heading}
              key={DigitalServiceData[0].id}
            >
              <h4 className="solutions-tab-heading pb-3 pt-5">{DigitalServiceData[0].heading}</h4>
              <Row>
                <Col className="col-12 col-md-6 fs-6 service-tab-card-text mb-4">{DigitalServiceData[0].text}</Col>
                <Col className="col-12 col-md-6 text-center order-first order-md-last mb-5"><img src={DigitalServiceData[0].image} alt={DigitalServiceData[0].heading} className="services-tab-image" /></Col>
              </Row>
              <Row>
                {DigitalServiceData[0].data.map(({ id, heading, text }) => (
                  <Col key={id} className="col-12 mb-4 col-lg-6 h-100" id={heading}>
                    <Card className="digital-services-card">
                      <Card.Body>
                        <Card.Title className="digital-services-card-heading">{heading}</Card.Title>
                        <Card.Text>{text}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Tab>
            {OurServicesData[0].data.map(({
              id, heading, key, text, image,
            }) => (
              <Tab eventKey={key} title={heading} key={id} id={key}>
                <h4 className="solutions-tab-heading pb-3 pt-5">{heading}</h4>
                <Row>
                  <Col className="col-12 col-md-6 fs-6 service-tab-card-text">{text}</Col>
                  <Col className="col-12 col-md-6 text-center order-first order-md-last mb-5"><img src={image} alt={heading} className="services-tab-image" /></Col>
                </Row>
              </Tab>
            ))}
          </Tabs>
        </Row>
      </Container>
    </section>

  );
}
